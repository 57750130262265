







import { Vue, Component } from "vue-property-decorator";
import { initViewportForSp, revertViewport } from "@/utils/lp-handler";

@Component
export default class extends Vue {
  public async mounted() {
    initViewportForSp();
  }
  private beforeDestroy() {
    revertViewport();
  }
}
