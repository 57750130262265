






import {Component, Vue} from 'vue-property-decorator';
import {handle} from '@/views/label/AddSubscription.vue';
import {MessageBox} from "element-ui";
import {PaymentChannel} from "@/repositories/company/payment-repository";

@Component
export default class extends Vue {
  created() {
    if (!this.$auth.user.isCompanyAdmin) {
      return MessageBox.confirm('契約の追加は管理メンバーのみ可能です', '「スマート食品規格書」の利用開始', {
        confirmButtonText: '戻る',
        showCancelButton: false,
        confirmButtonClass: 'c-button primary no-focus u-col24',
        closeOnClickModal: false,
        closeOnPressEscape: false,
      }).then(async () => {
        location.href = `/label/companies/${this.$auth.user.company.id}/logged-in`
      });
    }

    if (this.$auth.user.labelSubscription && this.$auth.user.labelSubscription.paymentChannel === PaymentChannel.Invoice) {
      return MessageBox.confirm('現在のご契約では、このサービスはご利用できません。ご利用をご希望の場合は、右下の「チャット欄」もしくは「お問い合わせ」より、お申し付けください。', 'ご契約の対象外', {
        confirmButtonText: '戻る',
        showCancelButton: false,
        confirmButtonClass: 'c-button primary no-focus u-col24',
        closeOnClickModal: false,
        closeOnPressEscape: false,
      }).then(async () => {
        location.href = `/label/companies/${this.$auth.user.company.id}/logged-in`
      });
    }

    const msg = '<p>既に他サービスにてアカウントをお持ちのため、<br><b>「スマート食品規格書」</b>をご利用いただくには、以下の同意が必要です。</p>' +
                "<ul class='c-checklist u-mt25'>" +
                  "<li class='el-icon-circle-check'>利用開始時の選択プランは、フリープラン（価格：0円/月）となります。</li>" +
                  "<li class='el-icon-circle-check'>規格書の作成は無料ですが、回収管理は数によって月額プランが自動で最適なものに変化します。詳細は <a href='/spec' target='_blank' class='u-link'>コチラ</a> をご覧ください。</li>" +
                  "<li class='el-icon-circle-check'><a href='/static/terms' target='_blank' class='u-link'>利用規約</a> と <a href='/static/privacy' target='_blank' class='u-link'>プライバシーポリシー</a></li>" +
                "</ul>";
    handle(msg, this.$auth.user).then(() => {
      location.href = this.$router.resolve({name: 'auth.signup.complete'}).href;
    }).catch((err) => {
      if (err === 'cancel') {
        location.href = `/label/companies/${this.$auth.user.company.id}/logged-in`
        return;
      }
      throw err;
    })
  }
}
