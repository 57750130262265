








































































import { Vue, Component, Prop} from 'vue-property-decorator';
import LoadingHandler from '@/utils/loading-handler';

import Qs from 'qs';
import SpecShareRequestViaEmailRepository, {
  SpecShareRequestForConfirmation
} from "@/repositories/spec/company/spec-share-request-via-email-repository";
import SpecTab from "@/views/spec/companies/components/spec-components/create/components/SpecTab.vue";
import ProfileForm from "@/views/common/auth/components/ProfileForm.vue";
import AuthRepository, {EmailVerificationParams, SignupRequest} from "@/repositories/auth-repository";
import Agreement from "@/views/common/auth/components/Agreement.vue";
import LoginForm from "@/views/common/auth/components/LoginForm.vue";
import ConfirmShareRequestBanner from "@/views/spec/confirm-share-request/ConfirmShareRequestBanner.vue";
import {AuthUser} from "@/libs/auth";
@Component({
  components: {ConfirmShareRequestBanner, LoginForm, Agreement, ProfileForm, SpecTab}
})
export default class extends Vue {
  @Prop({required: true}) private specShareRequestId!: number;

  private request: SpecShareRequestForConfirmation = null as any;
  private initialized = false;

  private showMfa: boolean = false;

  private tab: 'current' | 'signup' | 'login' = 'signup';
  private get isAuthenticated() {
    return this.$auth.isAuthenticated;
  }
  private get token(): EmailVerificationParams {
    return Qs.parse(location.search.substring(1)) as any;
  }

  private async created() {
    await LoadingHandler(async () => {
      this.request = await (new SpecShareRequestViaEmailRepository()).fetchRequest(this.specShareRequestId, this.token);
    });

    // ひも付き済みの場合
    if (!!this.request.currentSpecShareSubmitterId) {
      if(this.$auth.isAuthenticated && this.$auth.user.company.id === this.request.currentSpecShareSubmitterId) {
        // ひも付き済みのアカウントでログイン中 => 提出画面にリダイレクト
        this.forwardToIndex();
        this.$message({ type: 'info', message: this.$t('この提出依頼は、すでにログイン中の組織で受付済みです。'), showClose: true });
      } else {
        // それ以外 => エラー表示（このURLは無効です的な)
        this.$message({ type: 'error', message: this.$t('この提出依頼は、すでに別の組織で受付されています。') });
      }

      return;
    }

    if(this.isAuthenticated) {
      this.tab = 'current';
    } else if(this.request!.emailExists) {
      this.tab = 'login';
    } else {
      this.tab = 'signup';
    }

    this.initialized = true;
  }

  private async bindRequest() {
    LoadingHandler(async () => {
      await (new SpecShareRequestViaEmailRepository()).bindRequest(this.specShareRequestId, this.token);
    }).then(() => {
      this.forwardToIndex();
      this.$message({ type: 'success', message: this.$t('依頼された商品を受付しました'), showClose: true });
    });
  }

  private async signup(data: SignupRequest) {
    await LoadingHandler(async () => {
      const user = await (new SpecShareRequestViaEmailRepository()).bindRequestWithSignup(this.specShareRequestId, this.token, data);
      this.$auth.login(user);
    });
    this.forwardToCreate();
    this.$message({type: 'success', message: this.$t("ユーザーが作成され、依頼された商品を受付しました。続いて規格書を入力してください。") });
  }

  private async login(user: AuthUser) {
    this.$auth.login(user);
    await this.bindRequest();
    this.$message({ type: 'success', message: this.$t('ログインし、依頼された商品を受付しました'), showClose: true });
  }

  private forwardToIndex() {
    this.$router.push({
      name: 'submission.index',
      params: { companyId: this.$auth.user.company.id as any},
      query: { specShareIdToSubmit: this.request!.specShareId as any }
    });
  }
  private forwardToCreate() {
    this.$router.push({
      name: 'submission.create',
      params: { companyId: this.$auth.user.company.id, specShareId: this.request!.specShareId } as any,
    });
  }
}

