import RepositoryBase from "@/repositories/repository-base";
import {EmailVerificationParams, SignupRequest} from "@/repositories/auth-repository";
import {AuthUser} from "@/libs/auth";
import ObjectUtils from "@/utils/object-utils";
import parseISO from "date-fns/parseISO";
import differenceInDays from "date-fns/differenceInDays";

export default class SpecShareRequestViaEmailRepository extends RepositoryBase<any> {
  public async fetchRequest(requestId:number, token: EmailVerificationParams): Promise<SpecShareRequestForConfirmation> {
    const res = await this.get(`spec-share-request-via-email/${requestId}/`, {
      token: token
    });
    return new SpecShareRequestForConfirmation(res.data);
  }
  public async bindRequest(requestId:number, token: EmailVerificationParams): Promise<void> {
    await this.post(`spec-share-request-via-email/${requestId}/bind`, {
      token: token
    });
  }
  public async bindRequestWithSignup(requestId:number, token: EmailVerificationParams, user:SignupRequest): Promise<AuthUser> {
    const res = await this.post(`spec-share-request-via-email/${requestId}/signup-and-bind`, {
      token: token,
      user: user,
    });
    return new AuthUser(res.data);
  }
}

export class SpecShareRequestForConfirmation {
  public requestProductName!:string;
  public otherProductNames!:string[];
  public requesterName!:string;
  public specShareId!:number;
  public submitterEmail!:string;
  public emailExists!:boolean;
  public dueDate!: Date;
  public currentSpecShareSubmitterId!:number|null;

  constructor(init:SpecShareRequestForConfirmation) {
    ObjectUtils.assignLiteralFields(this, init);
    this.otherProductNames = init.otherProductNames;
    this.dueDate = parseISO(init.dueDate as any);
  }

  public getRestDaysUntilDueDate(today: Date = new Date()): number {
    return differenceInDays(this.dueDate, today);
  }
}
