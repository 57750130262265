






import {Component, Vue} from 'vue-property-decorator';
import LoadingHandler from "@/utils/loading-handler";
import PaymentRepository, {PaymentChannel} from "@/repositories/company/payment-repository";
import {MessageBox} from "element-ui";
import {AuthUser} from "@/libs/auth";

export async function showConfirmation(msg:string) {
  return MessageBox.confirm(msg, 'サービスの利用開始', {
    confirmButtonText: '以上に同意して利用を開始する',
    dangerouslyUseHTMLString: true,
    cancelButtonText: 'キャンセル',
    cancelButtonClass: 'c-button primary-inverse no-focus',
    confirmButtonClass: 'c-button primary no-focus u-col18',
    closeOnClickModal: false,
    closeOnPressEscape: false,
  });
}

export async function handle(msg:string, user:AuthUser) {
  return showConfirmation(msg).then(async () => {
    await LoadingHandler(async () => {
      await (new PaymentRepository(user.company.id)).appendSubscription();
    })
  });
}

@Component
export default class extends Vue {
  created() {
    if (!this.$auth.user.isCompanyAdmin) {
      return MessageBox.confirm('契約の追加は管理メンバーのみ可能です', '「スマート食品表示」の利用開始', {
        confirmButtonText: '戻る',
        showCancelButton: false,
        confirmButtonClass: 'c-button primary no-focus u-col24',
        closeOnClickModal: false,
        closeOnPressEscape: false,
      }).then(async () => {
        location.href = `/spec/companies/${this.$auth.user.company.id}/logged-in`
      });
    }

    if (this.$auth.user.specSubscription && this.$auth.user.specSubscription.paymentChannel === PaymentChannel.Invoice) {
      return MessageBox.confirm('現在のご契約では、このサービスはご利用できません。ご利用をご希望の場合は、右下の「チャット欄」もしくは「お問い合わせ」より、お申し付けください。', 'ご契約の対象外', {
        confirmButtonText: '戻る',
        showCancelButton: false,
        confirmButtonClass: 'c-button primary no-focus u-col24',
        closeOnClickModal: false,
        closeOnPressEscape: false,
      }).then(async () => {
        location.href = `/spec/companies/${this.$auth.user.company.id}/logged-in`
      });
    }

    const msg = '<p>既に他サービスにてアカウントをお持ちのため、<br><b>「スマート食品表示」</b>をご利用いただくには、以下の同意が必要です。</p>' +
      "<ul class='c-checklist u-mt25'>" +
      "<li class='el-icon-circle-check'>利用開始時の選択プランは、スタータープラン（価格：2,480円/月）となりますが、14日間の体験期間があります。体験期間中にご解約いただくと、一切費用は発生いたしません。</li>" +
      "<li class='el-icon-circle-check'><a href='/static/terms' target='_blank' class='u-link'>利用規約</a> と <a href='/static/privacy' target='_blank' class='u-link'>プライバシーポリシー</a></li>" +
      "</ul>";

    if (!this.$auth.user.isCardRegistered) {
      return showConfirmation(msg).then(() => {
        location.href = this.$router.resolve({name: 'auth.signup.register-card'}).href;
      }).catch((err) => {
        if (err === 'cancel') {
          location.href = `/spec/companies/${this.$auth.user.company.id}/logged-in`
          return;
        }
        throw err;
      })
    }

    handle(msg, this.$auth.user).then(() => {
      location.href = this.$router.resolve({name: 'auth.signup.complete'}).href;
    }).catch((err) => {
      if (err === 'cancel') {
        location.href = `/spec/companies/${this.$auth.user.company.id}/logged-in`
        return;
      }
      throw err;
    })
  }
}
