





































import { Vue, Component, Prop} from 'vue-property-decorator';
import LoadingHandler from '@/utils/loading-handler';

import Qs from 'qs';
import SpecShareRequestViaEmailRepository, {
  SpecShareRequestForConfirmation
} from "@/repositories/spec/company/spec-share-request-via-email-repository";
import SpecTab from "@/views/spec/companies/components/spec-components/create/components/SpecTab.vue";
import ProfileForm from "@/views/common/auth/components/ProfileForm.vue";
import AuthRepository, {EmailVerificationParams, SignupRequest} from "@/repositories/auth-repository";
import Agreement from "@/views/common/auth/components/Agreement.vue";
import LoginForm from "@/views/common/auth/components/LoginForm.vue";
@Component
export default class extends Vue {
}

